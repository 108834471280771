import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { useAuth } from './contexts/AuthContext';
import { makeAdminUploadImage } from './main/factories/makeAdminUploadImage';
import { makeCreateCampaing } from './main/factories/makeCreateCampaing';
import { makeLoadMyHubCampanha } from './main/factories/makeLoadMyHubCampanha';
import { makeRankingTridentPage } from './main/factories/makeRankingTridentPage';
import { makeUpdateCampaing } from './main/factories/makeUpdateCampaing';
import NotFound from './pages/404';
import AddNewUser from './pages/AddNewUser';
import AdminHome from './pages/Admin';
import AdminBanner from './pages/Admin/Banner';
import AdminBlog from './pages/Admin/Blog';
import NewPost from './pages/Admin/Blog/NewPost';
import AdminCampaign from './pages/Admin/Campaign';
import EditCampaign from './pages/Admin/Campaign/EditCampaign';
import NewCampaign from './pages/Admin/Campaign/NewCampaign';
import Dashboard from './pages/Admin/Dashboard';
import AdminGiftCards from './pages/Admin/GiftCards';
import AdminMarketplaceBanner from './pages/Admin/MarketplaceBanner';
import NotApproved from './pages/Admin/NotApprovedUsers';
import AdminQuiz from './pages/Admin/Quiz';
import RankingSelector from './pages/Admin/RankingSelector';
import AdminReports from './pages/Admin/Reports';
import AdminRewards from './pages/Admin/Rewards';
import AdminStoreSalesStatement from './pages/Admin/SalesStatement';
import AdminNotice from './pages/AdminNotice';
import AdminNoticeUser from './pages/AdminNoticeUser';
import AdminRoom from './pages/AdminRoom';
import AdminRoomNew from './pages/AdminRoom/New';
import AdminUpload from './pages/AdminUpload';
import Auction from './pages/Auction';
import Badges from './pages/Badges';
import Blog from './pages/Blog';
import CampaignHub from './pages/CampaignHub';
import Category from './pages/Category';
import ClientCallback from './pages/ClientCallback';
import DashboardPowerBI from './pages/DashboardPowerBI';
import Downloads from './pages/Downloads';
import EditUser from './pages/EditUser';
import EmBreve from './pages/EmBreve';
import FaleConosco from './pages/FaleConosco';
import Faq from './pages/Faq';
import Forums from './pages/Forums';
import Gallery from './pages/Gallery';
import GiraGiro from './pages/Games/GiraGiro';
import JogoDaMemoria from './pages/Games/JogoDaMemoria';
import WednesdayPuzzle from './pages/Games/WednesdayPuzzle';
import Home from './pages/Home';
import Hub from './pages/Hub';
import CampaignHubMecanica from './pages/Hub/Mecanica';
import CampaignHubRegulation from './pages/Hub/Regulation';
import Live from './pages/Live';
import Login from './pages/Login';
import Activation from './pages/Login/Activation';
import RecoveryPassword from './pages/Login/Recovery';
import Marketplace from './pages/Marketplace';
import OrderDetails from './pages/Marketplace/OrderDetails';
import Product from './pages/Marketplace/Product';
import Cart from './pages/Marketplace/Product/Cart';
import Checkout from './pages/Marketplace/Product/Cart/Checkout';
import Mecanicas from './pages/Mecanica';
import Pascoa from './pages/Mecanica/Pascoa';
import MecanicasExtras from './pages/MecanicasExtras';
import Members from './pages/Members';
import Metas from './pages/Metas';
import MyResults from './pages/MyResults';
import Newsfeed from './pages/Newsfeed';
import Overview from './pages/Overview';
import Perfil from './pages/Perfil';
import AccountChangePassword from './pages/Perfil/tabs/Account/ChangePassword';
import AccountGeneralSettings from './pages/Perfil/tabs/Account/GeneralSettings';
import ProfileInfo from './pages/Perfil/tabs/Profile/ProfileInfo';
import ProfileMessages from './pages/Perfil/tabs/Profile/ProfileMessages';
import ProfileNotifications from './pages/Perfil/tabs/Profile/ProfileNotifications';
import ProfileBadges from './pages/Perfil/tabs/ProfileBadges';
import ProfileBlogPosts from './pages/Perfil/tabs/ProfileBlogPosts';
import ProfileEdit from './pages/Perfil/tabs/ProfileEdit';
import ProfileGroups from './pages/Perfil/tabs/ProfileGroups';
import ProfileMarketplace from './pages/Perfil/tabs/ProfileMarketplace';
import ProfileNewsfeed from './pages/Perfil/tabs/ProfileNewsfeed';
import ProfilePhotos from './pages/Perfil/tabs/ProfilePhotos';
import PhotoList from './pages/Perfil/tabs/ProfilePhotos/PhotoList';
import ProfileStore from './pages/Perfil/tabs/ProfileStore';
import StoreAccount from './pages/Perfil/tabs/Store/Account';
import StoreDownloads from './pages/Perfil/tabs/Store/Downloads';
import StoreManageItems from './pages/Perfil/tabs/Store/ManageItems';
import ProfileOrder from './pages/Perfil/tabs/Store/ProfileOrder';
import StoreSalesStatement from './pages/Perfil/tabs/Store/SalesStatement';
import PhotoToAccept from './pages/PhotoToAccept';
import PhotoToApprove from './pages/PhotoToApprove';
import PicPay from './pages/PicPay';
import PicPaySteps from './pages/PicPay/Steps';
import Providers from './pages/Providers';
import EditProvider from './pages/Providers/EditProvider';
import NewProvider from './pages/Providers/NewProvider';
import Questionario from './pages/Questionario';
import Quests from './pages/Quests';
import Quiz from './pages/Quiz';
import QuizInterno from './pages/QuizInterno';
import QuizTotal from './pages/QuizTotal';
import Ranking from './pages/Ranking';
import RankingAnual from './pages/Ranking/RankingAnual';
import Ranking2 from './pages/Ranking2';
import RankingDist from './pages/RankingDist';
import RankingHomolog from './pages/RankingHomolog';
import RankingQuizAtivo from './pages/RankingQuiz';
import RankingStaff from './pages/RankingStaff';
import RankingStaffHolding from './pages/RankingStaffHolding';
import Regulamento from './pages/Regulamento';
import StatusUsuarios from './pages/StatusUsuarios';
import Treinamentos from './pages/Treinamentos';
import Days100 from './pages/Treinamentos/100days';
import AprendaAVender from './pages/Treinamentos/AprendaAVender';
import ConstrutorDeVendas from './pages/Treinamentos/ConstrutorDeVendas';
import Digital2021 from './pages/Treinamentos/Digital2021';
import UpdateCampaignResults from './pages/UpdateCampaignResults';
import UserTerms from './pages/UserTerms';
import Usuarios from './pages/Usuarios';
import Winners from './pages/Winners';

function isAuthenticated () {
  const token = localStorage.getItem('token');
  if (!token) return false;
  return true;
}

function PrivateRoute ({ permission, ...rest }) {
  const { userCan } = useAuth();
  if (!isAuthenticated()) return <Navigate to={{ pathname: '/' }} />;
  if (!permission) return <Outlet />;
  if (userCan(permission)) return <Outlet />;
  return <Navigate to={{ pathname: '/' }} />;
}

export default function Rotas () {
  return (
    <Routes>
      <Route element={<Login />} path="/" />
      <Route element={<Activation />} path="activation/:token" />
      <Route element={<RecoveryPassword />} path="/recovery-password/:token" />
      <Route element={<ClientCallback />} path="/:clientName/callback" />
      <Route element={<QuizTotal />} path="/quiz/total/:quizId" />
      <Route element={<QuizInterno />} exact path="/quiz/:quizId" />
      <Route element={<Quiz />} path="/quiz" />
      <Route element={<RankingQuizAtivo />} path="/ranking-quiz" />
      <Route element={<EmBreve />} path="/em-breve" />
      <Route element={<PrivateRoute />}>
        <Route element={<Home />} path="/home" />
        <Route element={<Newsfeed />} path="/newsfeed" />
        <Route element={<Forums />} path="/forums" />
        <Route element={<Overview />} path="/overview" />
        <Route element={<Members />} path="/members" />
        <Route element={<Badges />} path="/badges" />
        <Route element={<Quests />} path="/quests" />
        <Route element={<MyResults />} path="/resultados" />
        <Route element={<MyResults />} path="/resultados/:userId" />
        <Route element={<Perfil />} path="/perfil">
          <Route index element={<ProfileInfo />} />
          <Route path="notifications" element={<ProfileNotifications />} />
          <Route path="messages" element={<ProfileMessages />} />

          <Route path="account">
            <Route path="password" element={<AccountChangePassword />} />
            <Route path="settings" element={<AccountGeneralSettings />} />
          </Route>

          <Route path="store">
            <Route path="account" element={<StoreAccount />} />
            <Route path="items" element={<StoreManageItems />} />
            <Route path="sales" element={<StoreSalesStatement />} />
            <Route path="downloads" element={<StoreDownloads />} />
          </Route>


          <Route path="newsfeed" element={<ProfileNewsfeed />} />
          <Route path="groups" element={<ProfileGroups />} />
          <Route path="photos/" element={<ProfilePhotos />} />
          <Route path="photos/:providerId" element={<PhotoList />} />
          <Route path="marketplace" element={<ProfileMarketplace />} />
          <Route path="blog-posts" element={<ProfileBlogPosts />} />
          <Route path="badges" element={<ProfileBadges />} />
          <Route path="store" element={<ProfileStore />} />
          <Route path="edit" element={<ProfileEdit />} />
        </Route>

        <Route path="/order/:orderId" element={<ProfileOrder />} />
        <Route element={<Mecanicas />} path="/mecanicas" />
        <Route element={<FaleConosco />} path="/fale-conosco" />
        <Route element={<Providers />} path="/providers" />
        <Route element={<NewProvider />} path="/providers/new" />
        <Route element={<EditProvider />} path="/providers/:providerId" />
        <Route element={<Faq />} path="/faq" />
        <Route element={<Regulamento />} path="/regulamento" />
        <Route element={<UserTerms />} path="/terms" />
        <Route element={<Cart />} path="/cart" />
        <Route element={<OrderDetails />} path="/detalhes-do-pedido" />
        <Route element={<Checkout />} path="/checkout" />
        <Route element={<Treinamentos />} path="/treinamentos" />
        <Route element={<Days100 />} path="/treinamentos/100-days" />
        <Route
          element={<AprendaAVender />}
          path="/treinamentos/aprenda-a-vender"
        />
        <Route element={<Digital2021 />} path="/treinamentos/digital-2021" />
        <Route
          element={<ConstrutorDeVendas />}
          path="/treinamentos/construtor-de-vendas"
        />
        <Route element={<Marketplace />} path="/marketplace" />
        <Route element={<Category />} path="/marketplace-category" />
        <Route element={<Category />} path="/marketplace-category/:departamentId" />
        <Route element={<Product />} path="/product/:cod" />
        <Route element={<Auction />} path="/leilao" />
        <Route element={<Downloads />} path="/downloads" />
        <Route element={<Ranking />} path="/ranking" />
        <Route element={<Ranking2 />} path="/ranking2" />
        <Route element={<RankingAnual />} path="/ranking-anual" />
        <Route element={<RankingHomolog />} path="/ranking/:worksheetId" />
        <Route element={<RankingSelector />} path="/ranking/homolog" />
        <Route element={<RankingDist />} path="/dist/ranking" />
        <Route element={<Metas />} path="/metas" />
        <Route element={<Blog />} path="/blog/:slug" />
        <Route element={<Hub />} path="/hub" />
        <Route element={<AdminHome />} exact path="/admin" />
        <Route element={<AdminStoreSalesStatement />} exact path="/admin/sales" />
        <Route element={<AdminRoom />} exact path="/admin/room" />
        <Route element={<AdminRoomNew />} exact path="/admin/room/new" />
        <Route element={<AdminBanner />} exact path="/admin/banner" />
        <Route element={<AdminMarketplaceBanner />} exact path="/admin/marketplace/banner" />
        <Route
          permission="newsfeed:create"
          element={<AdminNotice />}
          exact
          path="/admin/notice"
        />
        <Route
          permission="users:read"
          element={<Usuarios />}
          path="/admin/usuarios"
          exact
        />
        <Route
          permission="users:create"
          element={<AddNewUser />}
          path="/admin/usuarios/novo"
        />
        <Route
          permission="users:update"
          element={<EditUser />}
          path="/admin/usuarios/:userId"
        />
        <Route
          permission="results:import"
          element={<AdminUpload type="results" />}
          path="/admin/results"
        />
        <Route
          permission="newsfeed:create"
          element={<AdminNoticeUser />}
          path="/admin/notice/:userId"
        />
        <Route
          permission="users:approve"
          element={<NotApproved />}
          path="/admin/not-approved/"
        />
        <Route element={<Dashboard />} path="/admin/dashboard-old" />
        <Route element={<DashboardPowerBI />} path="/admin/dashboard" />
        <Route element={<AdminReports />} path="/admin/reports/" />
        <Route element={<PhotoToApprove />} path="/admin/photo-approve/" />
        <Route element={<PhotoToAccept />} path="/admin/photo-accept/" />
        <Route element={<RankingStaff />} path="/admin/ranking" />
        <Route
          element={<RankingStaffHolding />}
          path="/admin/ranking/:holdingId"
        />
        <Route element={<AdminQuiz />} path="/admin/quiz" />
        <Route element={<AdminQuiz />} path="/admin/quiz/:quizId" />
        <Route element={<AdminBlog />} path="/admin/blog" />
        <Route element={<AdminBlog />} path="/blog" />
        <Route element={<NewPost />} path="/admin/blog/post" />
        <Route
          permission="user-status:read"
          element={<StatusUsuarios />}
          path="/admin/user-status/"
        />
        <Route element={<AdminGiftCards />} path="/admin/giftcards" />
        <Route element={<AdminRewards />} path="/admin/resgate-de-premios" />

        <Route element={<AdminCampaign />} path="/admin/campanha" />
        <Route element={<NewCampaign uploadImage={makeAdminUploadImage()} createCampaing={makeCreateCampaing()} />} path="/admin/campanha/new" />
        <Route element={<EditCampaign uploadImage={makeAdminUploadImage()} updateCampaing={makeUpdateCampaing()} loadCampaing={makeLoadMyHubCampanha()} />} path="/admin/campanha/:campaingId" />
        <Route element={<UpdateCampaignResults />} path="/admin/campanha/:campanhaId/atualizar-resultados" />

        <Route element={<MecanicasExtras />} path="/mecanicas/:name" />
        <Route element={<Pascoa />} path="/mecanicas/pascoa" />

        <Route element={<Live />} path="/live/:liveId" />

        <Route path="/jogos">
          <Route element={<JogoDaMemoria />} path="memoria" />
          <Route element={<GiraGiro />} path="gira-giro" />
          <Route element={<WednesdayPuzzle />} path="pontos-no-susto" />
        </Route>

        <Route element={<Questionario />} path="/questionario" />

        <Route element={<Gallery />} path="/galeria" />

        <Route element={<Winners />} path="/ganhadores" />

        <Route element={<CampaignHub />} path="hub-de-campanhas" />
        <Route element={<CampaignHubRegulation />} path="hub-de-campanhas/:slug" />
        <Route element={<CampaignHubMecanica />} path="hub-de-campanhas/mecanica/:slug" />
        <Route element={makeRankingTridentPage()} path="hub-de-campanhas/:campaingId/ranking" />
        <Route element={<PicPay />} path="/picpay" />
        <Route element={<PicPaySteps />} path="/picpay/passo-a-passo" />
      </Route>
      <Route element={<NotFound />} path="*" />
    </Routes>
  );
}
