import { ReactElement } from 'react'
import { Link } from 'react-router-dom'
import logo from '../../../assets/img/hub/games/LOGO-GIGANTES.png'
import airplane from '../../../assets/img/hub/games/airplane.png'
import arrowDown from '../../../assets/img/hub/games/arrow-down.png'
import arrowUp from '../../../assets/img/hub/games/arrow-up.png'
import boyPlaying from '../../../assets/img/hub/games/boy-playing.png'
import coins from '../../../assets/img/hub/games/coins.png'
import giraBoy from '../../../assets/img/hub/games/gira-boy.png'
import giroBanner from '../../../assets/img/hub/games/gira-giro-banner.png'
import girl from '../../../assets/img/hub/games/girl.png'
import giroRoulette from '../../../assets/img/hub/games/giro-roulette.png'
import map from '../../../assets/img/hub/games/map.png'
import memoriesBanner from '../../../assets/img/hub/games/memories-banner.png'
import { useAuth } from '../../../contexts/AuthContext'
import SectionHeaderTitle from '../SectionHeaderTitle'
import QuizBisCard from './QuizBisCard'
import wednesdayBanner from './assets/wednesday-banner.png'
import wednesdayProducts from './assets/wednesday-produtos.png'
import * as S from './styled'

export default function Games (): ReactElement {
  const { userCan } = useAuth()
  return <>
    <S.CardsContainer>
      <S.MemoryContainer>
        <SectionHeaderTitle title="Games" />
        <S.LinkContainer to="/leilao">
          <S.MemoryCard>
            <S.MemoryContentContainer>
              <figure>
                <img src={memoriesBanner} alt="" />
              </figure>
            </S.MemoryContentContainer>

            <S.MemoryContent>
              <p>Leilão</p>

              <h1>
                Leilão de
                <br />
                <span>Gigantes</span>
              </h1>
            </S.MemoryContent>

            <figure>
              <img src={boyPlaying} alt="Garoto jogando" />
            </figure>
          </S.MemoryCard>
        </S.LinkContainer>
      </S.MemoryContainer>
      {userCan('play:memoria') && <S.MemoryContainer>
        <SectionHeaderTitle title="Games" />

        <S.LinkContainer to="/jogos/memoria">
          <S.MemoryCard>
            <S.MemoryContentContainer>
              <figure>
                <img src={memoriesBanner} alt="" />
              </figure>
            </S.MemoryContentContainer>

            <S.MemoryContent>
              <p>Jogar</p>

              <h1>
                Memória de
                <br />
                <span>Gigantes</span>
              </h1>
            </S.MemoryContent>

            <figure>
              <img src={boyPlaying} alt="Garoto jogando" />
            </figure>
          </S.MemoryCard>
        </S.LinkContainer>
      </S.MemoryContainer>}

      {userCan('play:gira-giro') && <S.GiroContainer>
        <S.LinkContainer to="/jogos/gira-giro">
          <S.GiroCard>
            <S.GiroContentContainer>
              <S.GiroRoulette>
                <img src={giroRoulette} alt="" />
              </S.GiroRoulette>

              <S.Logo>
                <img src={logo} alt="" />
              </S.Logo>

              <S.GiroBoy>
                <img src={giraBoy} alt="" />
              </S.GiroBoy>

              <S.GiroCoins>
                <img src={coins} alt="" />
              </S.GiroCoins>

              <figure>
                <img src={giroBanner} alt="" />
              </figure>
            </S.GiroContentContainer>

            <S.GiroContent>
              <p>Jogar</p>

              <S.GiroTitleContainer>
                <figure>
                  <img src={arrowUp} alt="" />
                </figure>

                <h1>
                  <span>Giga</span>
                  <br />
                  Giro
                </h1>

                <figure>
                  <img src={arrowDown} alt="" />
                </figure>
              </S.GiroTitleContainer>
            </S.GiroContent>
          </S.GiroCard>
        </S.LinkContainer>
      </S.GiroContainer>}

      {userCan('play:no-susto') && <S.WednesdayContainer>
        <S.LinkContainer to="/jogos/pontos-no-susto">
          <S.WednesdayCard>
            <S.WednesdayContentContainer>
              <S.WednesdayProducts>
                <img src={wednesdayProducts} alt="" />
              </S.WednesdayProducts>

              <figure>
                <img src={wednesdayBanner} alt="" />
              </figure>
            </S.WednesdayContentContainer>

            <S.WednesdayContent>
              <p>Jogar</p>

              <h1>
                Pontos
                <br />
                no Susto
              </h1>
            </S.WednesdayContent>
          </S.WednesdayCard>
        </S.LinkContainer>
      </S.WednesdayContainer>}

      <QuizBisCard />

      {userCan('read:survey') && <S.TripCard>
        <S.TripTitleContainer>
          <h1>Seu destino é Gigante</h1>
        </S.TripTitleContainer>

        <S.TripContent>
          <Link to="/questionario">
            <div>
              <p>
                Escolha seu
                <br />
                destino
              </p>

              <figure>
                <img src={airplane} alt="" />
              </figure>

              <figure>
                <img src={map} alt="" />
              </figure>
            </div>
          </Link>

          <figure>
            <img src={girl} alt="" />
          </figure>
        </S.TripContent>
      </S.TripCard>}
    </S.CardsContainer>
  </>
}
