import { ReactElement } from 'react'
import image1 from './assets/01_Vendas_Onda_1_(1).jpg'
import image2 from './assets/02_Vendas_Onda_1_(2).jpg'
import image3 from './assets/03_Vendas_Onda_1_(3).jpg'
import image4 from './assets/04_Vendas_Onda_1_(4).jpg'
import image5 from './assets/05_Vendas_Onda_1_(5).jpg'
import image6 from './assets/06_Vendas_Onda_1_(6).jpg'
import image7 from './assets/07_Vendas_Onda_1_(7).jpg'
import image8 from './assets/08_Vendas_Onda_1_(8).jpg'
import image9 from './assets/09_Vendas_Onda_1_(9).jpg'
import image10 from './assets/10_Vendas_Onda_1_(10).jpg'
import image11 from './assets/11_Vendas_Onda_1_(11).jpg'
import image12 from './assets/12_Vendas_Onda_1_(12).jpg'
import image13 from './assets/13_Vendas_Onda_1_(13).jpg'
import image14 from './assets/14_Vendas_Onda_1_(14).jpg'
import image15 from './assets/15_Vendas_Onda_1_(15).jpg'
import image16 from './assets/16_Vendas_Onda_1_(16).jpg'
import image17 from './assets/17_Vendas_Onda_1_(17).jpg'
import image18 from './assets/18_Vendas_Onda_1_(18).jpg'
import image19 from './assets/19_Vendas_Onda_1_(19).jpg'
import image20 from './assets/20_Vendas_Onda_1_(20).jpg'
import image21 from './assets/21_Vendas_Onda_1_(21).jpg'
import image22 from './assets/22_Vendas_Onda_1_(22).jpg'
import image23 from './assets/23_Vendas_Onda_1_(23).jpg'
import image24 from './assets/24_Vendas_Onda_1_(24).jpg'
import image25 from './assets/25_MERCHAN_Onda_1_(25).jpg'
import image26 from './assets/26_MERCHAN_Onda_1_(26).jpg'
import image27 from './assets/27_MERCHAN_Onda_1_(27).jpg'
import image28 from './assets/28_MERCHAN_Onda_1_(28).jpg'
import image29 from './assets/29_MERCHAN_Onda_1_(29).jpg'
import image30 from './assets/30_MERCHAN_Onda_1_(30).jpg'
import image31 from './assets/31_MERCHAN_Onda_1_(31).jpg'
import image32 from './assets/32_MERCHAN_Onda_1_(32).jpg'
import image33 from './assets/33_MERCHAN_Onda_1_(33).jpg'
import * as S from './style'

const images = [image1, image2, image3, image4, image5, image6, image7, image8, image9, image10, image11, image12, image13, image14, image15, image16, image17, image18, image19, image20, image21, image22, image23, image24, image25, image26, image27, image28, image29, image30, image31, image32, image33
]

export default function Recognition (): ReactElement {
  return <S.Container>
      {images.map((image) => <img src={image} alt="" />)}
    </S.Container>
}
