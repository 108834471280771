import axios from 'axios'
import { saveAs } from 'file-saver'
import { FormEvent, ReactElement, useCallback, useEffect, useLayoutEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import PageLoaderComponent from '../../../../components/PageLoader'
import api from '../../../../services/api'
import { EditCampaignProps } from './props'
import StepOne from './StepOne'
import StepTwo from './StepTwo'
import * as S from './style'

export enum State {
  STEP_ONE,
  STEP_TWO,
  LOADING
}
export interface CampaignInfo {
  currentState: State
  description: string
  banner: string
  mechanics: string[]
  mecanicas?: string
  logo: string
  name: string
  report: string[]
  results: string[]
  smallBanner: string
  target: string[]
  regulation: string
  roles: number[]
}

export interface CampaignErrors {
  hasError?: boolean
  banner?: string
  description?: string
  logo?: string
  mechanics?: string
  name?: string
  regulation?: string
  smallBanner?: string
}

interface PageParams {
  campaingId: string
}

export default function EditCampaign ({ uploadImage, loadCampaing, updateCampaing }: EditCampaignProps): ReactElement {
  const navigate = useNavigate()
  const { campaingId } = useParams() as unknown as PageParams
  const [errors, setErrors] = useState<CampaignErrors>({})
  const [state, setState] = useState<CampaignInfo>({
    currentState: State.LOADING,
    description: '',
    mechanics: [],
    report: [],
    results: [],
    name: '',
    target: [],
    regulation: '',
    banner: '',
    logo: '',
    smallBanner: '',
    roles: []
  })

  const handleSubmit = useCallback(
    async (evt: FormEvent<HTMLFormElement>) => {
      evt.preventDefault()
      if (!campaingId) return
      try {
        await updateCampaing.handle({ id: campaingId, ...state })
        toast.success('Campanha atualizada com sucesso!', { theme: 'colored' })
        navigate('/admin/campanha')
      } catch (error) {
        if (axios.isAxiosError(error)) {
          if (error.response?.status !== 500) {
            console.log(error.response?.data)
            const [, param] = error.response?.data.toString().match(/: (.*)$/)
            if (error.response?.data.match(/^Missing param/)) {
              toast.error(`O ${String(param)} precisa ser informado`, {
                theme: 'colored'
              })
            }
            return
          }
        }
        toast.error('Ocorreu um erro interno no servidor', { theme: 'colored' })
      }
    },
    [state, navigate]
  )

  const handleDownload = useCallback(async (campaingId: string) => {
    const response = await api.get(`/hub-campanha/minhas-campanhas/${campaingId}/export`, { responseType: 'arraybuffer' })
    const blob = new Blob([response.data], {
      type: response.headers['content-type']
    })
    saveAs(blob, `hub-accept-${campaingId}.xlsx`)
  }, [])

  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, [state.currentState])

  useEffect(() => {
    if (!campaingId) return
    (async () => {
      const response = await loadCampaing.handle({ id: campaingId })
      setState({ ...response.data, regulation: response.data.regulation.content, currentState: State.STEP_ONE })
    })()
  }, [campaingId])

  useEffect(() => {
    setErrors(() => ({}))

    if (state.banner == null) {
      setErrors((prev) => ({
        ...prev,
        hasError: true,
        banner: 'O campo Banner é obrigatório'
      }))
    }

    if (state.description === '') {
      setErrors((prev) => ({
        ...prev,
        hasError: true,
        description: 'O campo Descrição é obrigatório'
      }))
    }

    if (state.logo == null) {
      setErrors((prev) => ({
        ...prev,
        hasError: true,
        logo: 'O campo Logo é obrigatório'
      }))
    }

    if (state.mechanics == null && state.mecanicas == null) {
      setErrors((prev) => ({
        ...prev,
        hasError: true,
        mechanics: 'O campo Mecânica é obrigatório'
      }))
    }

    if (state.name === '') {
      setErrors((prev) => ({
        ...prev,
        hasError: true,
        name: 'O campo Nome é obrigatório'
      }))
    }

    if (state.regulation === '<p><br></p>' || state.regulation == null) {
      setErrors((prev) => ({
        ...prev,
        hasError: true,
        regulation: 'O campo Regulamento é obrigatório'
      }))
    }

    if (state.smallBanner == null) {
      setErrors((prev) => ({
        ...prev,
        hasError: true,
        smallBanner: 'O campo Banner Pequeno é obrigatório'
      }))
    }
  }, [state])

  if (state.currentState === State.LOADING) return <PageLoaderComponent />

  return <S.Template>
    <div className="content-grid full">
      <article className="post-open">
        <form action="" method="post" onSubmit={handleSubmit}>
          <S.PostCard className="post-open-body">
            <S.LinkContainer>
              <button type="button" className="primary button" onClick={async () => await handleDownload(campaingId)}>Baixar Aceites</button>
            </S.LinkContainer>
            <div className="post-open-heading">
              <p className="post-open-timestamp">
                <span className="highlighted">
                  {new Date().toLocaleString('pt-BR')}
                </span>
              </p>
            </div>

            {state.currentState === State.STEP_ONE && <StepOne uploadImage={uploadImage} errors={errors} setState={setState} state={state} />}
            {state.currentState === State.STEP_TWO && <StepTwo uploadImage={uploadImage} errors={errors} setState={setState} state={state} />}
          </S.PostCard>
        </form>
      </article>

      <S.FormProgressContainer>
        <S.FormProgress $activeView={state.currentState >= State.STEP_ONE} />
        <S.FormProgress $activeView={state.currentState >= State.STEP_TWO} />
      </S.FormProgressContainer>
    </div>
  </S.Template>
}
